<template>
  <div>
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot name="titleBreadcrumb" :messageProps="$route.query.tipe"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="allcard" style="height: calc(100vh - (262px))">
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <div style="margin-top: -30px">
          <div class="titledashboard">
            <div>
              <div
                :style="
                  $route.query.tipe == 'Klaim Pending'
                    ? 'background: rgb(238 88 67 / 94%)'
                    : $route.query.tipe == 'Klaim Diproses Ulang'
                    ? 'background: rgb(255 153 0 / 81%)'
                    : 'background: rgb(76 136 255 / 83%)'
                "
              >
                <img
                  :src="require('@/assets/img/icon/pdg.svg')"
                  v-if="$route.query.tipe == 'Klaim Pending'"
                  style="filter: brightness(0) invert(1)"
                />

                <img
                  :src="require('@/assets/img/icon/mlk.svg')"
                  v-if="$route.query.tipe == 'Klaim Diproses Ulang'"
                  style="filter: brightness(0) invert(1)"
                />

                <img
                  :src="require('@/assets/img/icon/sdoa.svg')"
                  v-if="$route.query.tipe == 'Klaim Proses'"
                  style="filter: brightness(0) invert(1)"
                />
                <h3 style="color: #fff">{{ total }}</h3>
                <div
                  v-if="$route.query.tipe == 'Klaim Pending'"
                  style="color: #fff"
                >
                  Klaim Pending
                </div>
                <div
                  v-if="$route.query.tipe == 'Klaim Diproses Ulang'"
                  style="color: #fff"
                >
                  Klaim Kekurangan Dokumen
                </div>
                <div
                  v-if="$route.query.tipe == 'Klaim Proses'"
                  style="color: #fff"
                >
                  Klaim Sedang di-review Asuransi
                </div>
              </div>
            </div>
          </div>
        </div>

        <a-table
          :rowKey="(record) => record.id"
          :columns="columns"
          :dataSource="data"
          :pagination="false"
          :loading="loading"
          :scroll="{ x: 1200 }"
          @change="handleTableChange"
        >
          <div slot="filterDropdownMasuk" slot-scope="{ column }">
            <div class="wrapbuttonsort">
              <a-button
                type="link"
                @click="
                  handlesTbl('tanggal_masuk', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'tanggal_masuk'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Tanggal Masuk
                  </div>
                </div>
              </a-button>
            </div>
            <div>
              <a-button
                type="link"
                @click="
                  handlesTbl('tanggal_keluar', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'tanggal_keluar'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Tanggal Keluar
                  </div>
                </div>
              </a-button>
            </div>
          </div>

          <div
            slot="filterIconMasuk"
            slot-scope="filtered, column"
            class="customSorter"
          >
            <a-icon
              type="caret-up"
              :style="{
                color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
              }"
            />

            <a-icon
              type="caret-down"
              :style="{
                color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
              }"
            />
          </div>
          <div slot="filterDropdown" slot-scope="{ column }">
            <div class="wrapbuttonsort">
              <a-button
                type="link"
                @click="
                  handlesTbl('nama_peserta', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'nama_peserta'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Nama Pasien
                  </div>
                </div>
              </a-button>
            </div>
            <div>
              <a-button
                type="link"
                @click="
                  handlesTbl('no_peserta', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'no_peserta'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    No Peserta
                  </div>
                </div>
              </a-button>
            </div>
          </div>

          <div
            slot="filterIcon"
            slot-scope="filtered, column"
            class="customSorter"
          >
            <a-icon
              type="caret-up"
              :style="{
                color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
              }"
            />

            <a-icon
              type="caret-down"
              :style="{
                color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
              }"
            />
          </div>
          <template slot="setatus">
            Status
            <a-button type="link" @click="ModalStatus"
              ><a-icon type="info-circle"
            /></a-button>
          </template>
          <template slot="titlenamapasien">
            Nama Pasien<br />No. Peserta
          </template>
          <template slot="approval_date"> Tanggal<br />Approval </template>

          <template slot="titlekaryawan"> Nama<br />Karyawan </template>
          <template slot="jumlah_ajukan"> Jumlah<br />Diajukan </template>

          <template slot="tgl_masuk"> Tanggal<br />Masuk & Keluar </template>
          <template slot="ket_ekses"> Keterangan<br />Ekses </template>
          <!-- ------------------ -->
          <template slot="no_claim" slot-scope="text, record">
            <router-link
              :to="{ name: 'DetailKlaim', params: { id: record.id } }"
              >{{ text }}
              <a-icon
                type="right"
                style="
                  font-size: 11px !important;
                  font-weight: 500;
                  padding-left: 10px;
                "
            /></router-link>
          </template>

          <!-- ------------------ -->
          <template slot="alasan" slot-scope="text">
            <div v-if="text != null">
              <a-tooltip placement="leftTop">
                <template slot="title">
                  <ol style="padding-left: 10; margin: 0">
                    <li v-for="(item, i) in JSON.parse(text)" :key="i">
                      {{ item }}
                    </li>
                  </ol>
                </template>
                <ul class="listekses">
                  <li>
                    <span>1 </span>
                    <span
                      style="
                        white-space: nowrap;
                        display: inline-block;
                        width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      >{{ JSON.parse(text)[0] }}</span
                    >
                  </li>
                  <li v-if="JSON.parse(text).length > 2">
                    <span>2 </span> <span>...</span>
                  </li>
                  <li v-if="JSON.parse(text).length == 2">
                    <span>2 </span>
                    <span
                      style="
                        white-space: nowrap;
                        display: inline-block;
                        width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      >{{ JSON.parse(text)[1] }}</span
                    >
                  </li>
                  <li
                    v-if="
                      JSON.parse(text).length > 1 &&
                      JSON.parse(text).length != 2
                    "
                  >
                    <span>{{ JSON.parse(text).length }} </span>
                    <span
                      style="
                        white-space: nowrap;
                        display: inline-block;
                        width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      >{{ JSON.parse(text)[JSON.parse(text).length - 1] }}</span
                    >
                  </li>
                </ul>
              </a-tooltip>
            </div>

            <div v-else>-</div>
          </template>
          <!-- ------------------ -->
          <template slot="nama_pasien" slot-scope="text, record">
            <div :title="text">
              <router-link
                :to="{
                  name: 'DetailPeserta',
                  params: {
                    id: record.id_job_order,
                    idpeserta: record.id_peserta,
                  },
                }"
              >
                <span
                  style="
                    white-space: nowrap;
                    display: inline-block;
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{ text }}</span
                >
                <a-icon
                  type="right"
                  style="
                    font-size: 11px !important;
                    font-weight: bolder !important;
                    padding-left: 10px;
                    vertical-align: text-top;
                  "
                />
              </router-link>
            </div>
            <div>{{ record.no_peserta }}</div>
          </template>
          <!-- ------------------ -->

          <!--  -->

          <template slot="nama_karyawan" slot-scope="text, record">
            <router-link
              :to="{
                name: 'DetailPeserta',
                params: {
                  id: record.id_job_order,
                  idpeserta: record.id_karyawan_self,
                },
              }"
            >
              <span
                style="
                  white-space: nowrap;
                  display: inline-block;
                  width: 100px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ text }}</span
              >
              <a-icon
                type="right"
                style="
                  font-size: 11px !important;
                  font-weight: 500;
                  padding-left: 10px;
                "
              />
            </router-link>
          </template>

          <!--  -->
          <template slot="tgl_masuk_keluar" slot-scope="text, record">
            <div class="iconcalendar" style="padding-bottom: 10px">
              <img :src="require('@/assets/img/icon/periode_awal.svg')" />
              {{ record.tanggal_masuk }}
            </div>
            <div class="iconcalendar">
              <img :src="require('@/assets/img/icon/periode_akhir.svg')" />
              {{ record.tanggal_keluar }}
            </div>
          </template>
          <!-- ------------------ -->
          <template slot="jumlah_diajukan" slot-scope="text, record">
            <div v-if="text == null">-</div>
            <div v-else>
              <div style="font-weight: bold; color: #060d17">
                {{ record.mata_uang }}
              </div>
              <div style="font-weight: bold; color: #060d17">
                {{ numberWithCommas(text) }}
              </div>
            </div>
          </template>

          <!-- ------------------ -->
          <span slot="jumlah_bayar">Jumlah<br />Dibayarkan</span>
          <template slot="jumlah_dibayarkan" slot-scope="text, record">
            <div v-if="text == null">-</div>
            <div v-else>
              <div style="font-weight: bold; color: #060d17">
                {{ record.mata_uang_dibayarkan }}
              </div>
              <div style="font-weight: bold; color: #060d17">
                <!-- global variable -->
                {{ numberWithCommas(text) }}
              </div>
            </div>
          </template>

          <!-- ------------------ -->
          <template slot="ekses" slot-scope="text, record">
            <div v-if="text == null">-</div>
            <div v-else>
              <div style="font-weight: bold; color: #060d17">
                {{ record.mata_uang_ekses }}
              </div>
              <div style="font-weight: bold; color: #060d17">
                {{ numberWithCommas(text) }}
              </div>
            </div>
          </template>

          <!-- ------------------ -->

          <template slot="produk_plan" slot-scope="text">
            <div class="iconpeserta" v-if="text != 'NON PLAN/PRODUCT'">
              <img
                :src="require(`@/assets/img/icon/${text.toLowerCase()}.svg`)"
              />
              {{ text }}
            </div>
            <div v-else>-</div>
          </template>

          <template slot="status" slot-scope="text">
            <div class="wrapstatusclaim">
              <a-tooltip placement="top" v-if="text == '-'" title="HOLD">
                <!-- <div class="va" style="">
              <img :src="require('@/assets/img/icon/hold.svg')" /> HOLD
            </div> -->
                {{ text }}
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Verifikasi Asuransi'"
                title="Verifikasi Asuransi"
              >
                <div class="va">
                  <img :src="require('@/assets/img/icon/va.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Menunggu Verifikasi'"
                title="Menunggu Verifikasi"
              >
                <div class="mv">
                  <img :src="require('@/assets/img/icon/mv.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Proses Banding ke Asuransi'"
                title="Proses Banding ke Asuransi"
              >
                <div class="pb">
                  <img :src="require('@/assets/img/icon/pb.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Mohon Lengkapi Kekurangan Dokumen'"
                title="Mohon Lengkapi Kekurangan Dokumen"
              >
                <div class="mlk">
                  <img :src="require('@/assets/img/icon/mlk.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Pending'"
                title="Pending"
              >
                <div class="pdg">
                  <img :src="require('@/assets/img/icon/pdg.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Sedang di-review oleh Asuransi'"
                title="Sedang di-review oleh Asuransi"
              >
                <div class="sdoa">
                  <img :src="require('@/assets/img/icon/sdoa.svg')" />
                  {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Disetujui Dibayarkan Sebagian'"
                title="Disetujui Dibayarkan Sebagian"
              >
                <div class="dds">
                  <img :src="require('@/assets/img/icon/dds.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Disetujui Dibayarkan Penuh'"
                title="Disetujui Dibayarkan Penuh"
              >
                <div class="ddp">
                  <img :src="require('@/assets/img/icon/ddp.svg')" /> {{ text }}
                </div>
              </a-tooltip>

              <a-tooltip
                placement="top"
                v-if="text == 'Tidak Dicover'"
                title="Tidak Dicover"
              >
                <div class="td">
                  <img :src="require('@/assets/img/icon/td.svg')" /> {{ text }}
                </div>
              </a-tooltip>
            </div>
          </template>

          <template slot="tgl_approval" slot-scope="text">
            <div
              class="iconcalendar"
              style="padding-bottom: 10px"
              v-if="text != null"
            >
              <img
                :src="require('@/assets/img/icon/dateapproval.svg')"
                style="margin-right: -8px; vertical-align: middle"
              />
              {{ text }}
            </div>
            <div v-else>-</div>
          </template>

          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <div class="paginationcustom" style="margin-top: 0px !important">
          <a-pagination
            showSizeChanger
            @showSizeChange="onShowSizeChange"
            :pageSizeOptions="pageSizeOption"
            :pageSize.sync="pageSize"
            :total="total"
            v-model="page"
            :showTotal="
              (total, range) =>
                ` ${range[0]}-${range[1]}  of ${
                  pagination.total == undefined ? '0' : pagination.total
                } `
            "
            @change="handlePageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '1000000'">
                {{ props.value }} / page
              </span>
              <span v-if="props.value == '1000000'">All</span>
            </template>
          </a-pagination>
        </div>
      </simplebar>
    </div>
    <ModalStatus ref="action" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import ModalStatus from "@/components/Modal/StatusClaim.vue";
const columns = [
  // {
  //   dataIndex: "id",
  //   width: 45,
  //   slots: { title: "checkTitle" },
  //   align: "center",
  //   scopedSlots: { customRender: "SelectAll" },
  // },

  {
    dataIndex: "nama_peserta",
    scopedSlots: {
      customRender: "nama_pasien",
      title: "titlenamapasien",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
    customSort: "",
    choseSort: "",
    width: 180,
    fixed: "left",
  },
  {
    dataIndex: "nama_karyawan",
    scopedSlots: {
      customRender: "nama_karyawan",
      title: "titlekaryawan",
    },
    sorter: true,
    width: 180,
    fixed: "left",
  },
  {
    title: "No Claim",
    dataIndex: "no_claim",
    scopedSlots: { customRender: "no_claim" },
    sorter: true,
    width: 230,
  },

  {
    dataIndex: "status_claim",
    scopedSlots: { customRender: "status", title: "setatus" },
    sorter: true,
    width: 200,
    align: "center",
  },

  {
    title: "Product Plan",
    dataIndex: "produk",
    scopedSlots: { customRender: "produk_plan" },
    sorter: true,
    width: 150,
  },
  {
    dataIndex: "tgl_masuk",
    scopedSlots: {
      customRender: "tgl_masuk_keluar",
      title: "tgl_masuk",
      filterDropdown: "filterDropdownMasuk",
      filterIcon: "filterIconMasuk",
    },
    customSort: "",
    choseSort: "",
    width: 180,
  },

  {
    dataIndex: "jumlah_diajukan",
    scopedSlots: { customRender: "jumlah_diajukan", title: "jumlah_ajukan" },
    sorter: true,
    width: 180,
  },

  {
    dataIndex: "jumlah_dibayarkan",
    scopedSlots: {
      title: "jumlah_bayar",
      customRender: "jumlah_dibayarkan",
    },
    sorter: true,
    width: 180,

    // slots: { title: "jumlah_dibayarkan" },
  },

  {
    title: "Ekses",
    dataIndex: "ekses",
    scopedSlots: { customRender: "ekses" },
    sorter: true,
    width: 150,
  },

  {
    dataIndex: "alasan",
    scopedSlots: { customRender: "alasan", title: "ket_ekses" },
    sorter: true,
    width: 250,
  },
  {
    dataIndex: "tanggal_approval",
    scopedSlots: { customRender: "tgl_approval", title: "approval_date" },
    sorter: true,
    width: 200,
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   scopedSlots: { customRender: "action" },
  //   width: 250,
  //   align: "center",
  // },
];
export default {
  components: {
    simplebar,
    ModalStatus,
  },
  data() {
    return {
      data: [],
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 15,
      loading: false,
      columns: columns,
      heighttable: 0,
      model: [],
      breadcrumb: [
        {
          name: this.$route.query.tipe,
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetch({
      page: 1,
      pageSizes: 15,
      id_perusahaan: this.$route.query.id_job_order_perusahaan,
    });
  },
  methods: {
    ModalStatus() {
      this.$refs.action.Show();
    },
    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        search: this.search,
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = page;

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
      });
    },

    handlesTbl(item, sortby) {
      if (this.labels == "") {
        this.labels = item;
      }

      if (this.labels != item) {
        this.labels = item;
        this.click = 0;
      }

      console.log("labels", this.labels);
      this.click += 1;

      if (this.click == 3) {
        this.click = 0;
      }

      let sorting = {
        field: item,
        order: sortby[this.click],
      };
      this.handleTableChange(this.page, "", sorting);
      if (item == "nama_peserta" || item == "no_peserta") {
        this.columns[0].customSort = sortby[this.click];
        this.columns[0].choseSort = item;
      }

      if (item == "tanggal_keluar" || item == "tanggal_masuk") {
        this.columns[5].customSort = sortby[this.click];
        this.columns[5].choseSort = item;
      }

      //set columnya ke sorter

      //console.log(this.columns[1].choseSort);
    },

    handleTableChange(page, filter, sorter) {
      if (sorter.field != "nama_peserta" || sorter.field != "no_peserta") {
        this.columns[0].customSort = "";
      }

      if (sorter.field != "tanggal_keluar" || sorter.field != "tanggal_masuk") {
        this.columns[5].customSort = "";
      }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      this.pagination = pager;

      console.log(pager);
      this.fetch({
        page: pager.page,
        pageSizes: pager.pageSize,
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
        search: this.search,
        sortField: sorter.field,
        sortOrder: sorter.order,
      });
    },
    handleResize() {
      this.heighttable = screen.height - 480;
    },
    fetch(params = {}) {
      if (this.$route.query.tipe == "Klaim Pending") {
        this.loading = true;
        this.$store
          .dispatch("getListDashboardPending", {
            ...params,
          })
          .then((response) => {
            this.loading = false;

            const pagination = { ...this.pagination };
            pagination.total = response.data.total;
            pagination.pageSize = response.data.pageSize;
            pagination.page = response.data.page;

            this.total = pagination.total;
            this.data = response.data.data;
            this.pagination = pagination;
            this.model = [];
          });
      }
      if (this.$route.query.tipe == "Klaim Proses") {
        this.loading = true;
        this.$store
          .dispatch("getListDashboard", {
            ...params,
          })
          .then((response) => {
            this.loading = false;
            const pagination = { ...this.pagination };
            pagination.total = response.data.total;
            pagination.pageSize = response.data.pageSize;
            pagination.page = response.data.page;

            this.total = pagination.total;
            this.data = response.data.data;
            this.pagination = pagination;
            this.model = [];
          });
      }

      if (this.$route.query.tipe == "Klaim Diproses Ulang") {
        this.loading = true;
        this.$store
          .dispatch("getListDashboardPucl", {
            ...params,
          })
          .then((response) => {
            this.loading = false;

            const pagination = { ...this.pagination };
            pagination.total = response.data.total;
            pagination.pageSize = response.data.pageSize;
            pagination.page = response.data.page;

            this.total = pagination.total;
            this.data = response.data.data;
            this.pagination = pagination;
            this.model = [];
          });
      }
    },
  },
};
</script>
